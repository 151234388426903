import React, { useCallback, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import mapStyle from "../../data/map.json";

const PinMap = ({ onPinLocation }) => {
    const [selectedPosition, setSelectedPosition] = useState(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ["drawing"]
    });

    const handleMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setSelectedPosition({ lat, lng });
        if (onPinLocation) {
            onPinLocation({ lat, lng });
        }
    }, [onPinLocation]);

    return (
        <>
            {!isLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <div>
                    <GoogleMap
                        mapContainerClassName="map-container"
                        center={selectedPosition || { lat: 0, lng: 0 }}
                        zoom={selectedPosition ? 15 : 2} // Zoom in when a position is selected
                        options={{ styles: mapStyle }}
                        onClick={handleMapClick}
                    >
                        {selectedPosition && (
                            <Marker position={selectedPosition} />
                        )}
                    </GoogleMap>
                </div>
            )}
        </>
    );
};

export default PinMap;
