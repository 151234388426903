import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA, SEARCH_COLLECTION_URL} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import cogoToast from "cogo-toast";
import {getFinanceReport, payout, product} from "../../../api/app";
import moment from "moment";
import formatNumber from "../../../utils/formatNumber";
import DateRangePicker from "../../../components/common/DatePicker";

let currentDateRange = null;
let total = 0;

const Payout = () => {

    document.title = "Payout";

    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [editId, setEditId] = useState(0);

    const token = localStorage.getItem('jwtToken');
    const [selectedDate, setSelectedDate] = useState(false);
    const [currDateRange, setCurrDateRange] = useState(null);
    const [reset, setReset] = useState(new Date().getTime());

    useEffect(() => {
        let data = currentDateRange !== null ? {
            start_date: currentDateRange.start,
            end_date: currentDateRange.end
        } : {}

        getFinanceReport(data).then((response) => {
            if(!response.error) {
                $('#total-revenue').text(formatNumber(response.payload.total_revenue));
                $('#total-disbursed').text(formatNumber(response.payload.total_payout));
                $('#total-balance').text(formatNumber(((response.payload.total_revenue - response.payload.total_commission) - response.payload.total_payout).toFixed(2)));
                $('#total-commission').text(formatNumber(response.payload.total_commission));
                $('.current-date').text(response.payload.current_date);
            }
        });
    }, [reset]);

    useEffect(() => {
        let table = $('#td-payout').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Payout Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "payout",
                        fieldname: "type",
                        pageSize: data.start,
                        filter: currentDateRange !== null ? {
                            status: "paid",
                            "date.str":
                                {
                                    $gte: currentDateRange.start,
                                    $lte: currentDateRange.end
                                }
                        } : {status: "paid"},
                        format: 'json',
                        populate: "collector",
                        sort: {_id: -1},
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        total = res.total;
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `<div class="d-flex">
                            <div class="avatar wd-20 ht-20"><img src="${data.collector.logo_uri}" class="rounded-circle" alt="logo"></div>
                            <span class="pd-x-5">${capitalize(data.collector.name)}</span>
                        </div>`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `K${formatNumber(data.amount)}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return capitalize(data.gateway);
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return `<span class="tx-primary">${moment(data.period.start.str).format("Do MMM, YYYY")}</span> - <span class="tx-danger">${moment(data.period.end.str).format("Do MMM, YYYY")}</span>`
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 4
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link delete_payout"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.delete_payout', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.collector.name)} payout?`);
            $('#delete-payout').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                payout("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Payout successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        return () => {
            currentDateRange = null;
            total = 0;
            table.destroy();
        }

    }, []);

    const handleDateRangeSelect = (range) => {
        const formattedStartDate = $.datepicker.formatDate('dd/mm/yy', range.startDate);
        const formattedEndDate = $.datepicker.formatDate('dd/mm/yy', range.endDate);

        currentDateRange = {
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        };

        setCurrDateRange({
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        });

        setSelectedDate(true);

        reloadTable();
    };

    const refreshTable = (e) => {
        e.preventDefault();
        setSelectedDate(false);
        setCurrDateRange(null);
        currentDateRange = null;
        setReset(new Date().getTime());
        $('#td-payout').DataTable().ajax.reload(null, false);
    }

    const reloadTable = () => {
        setReset(new Date().getTime());
        $('#td-payout').DataTable().ajax.reload(null, false);
    };

    const handleShowModal = () => {
        setEditForm(false);
        $('#collector-select2').prop("disabled", false).prop("required", true);
        initializeCollectorSearch();
        $('#payout_form').parsley();
        $("#modalPayout").modal({ backdrop: 'static', focus: false, show: true });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let amount = $('#amount').val();
        let email = $('#email').val();

        setStateReady(true);

        let data = {
            amount: amount,
            email: email,
            ref_number: $('#ref_number').val(),
            gateway: $('#type').val(),
            collector: $('#collector-select2').val()[0]
        };

        payout("post", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalPayout", "payout_form");
                reloadTable();
            }
        });
    }

    const initializeCollectorSearch = () => {
        let modalPayout = $('#modalPayout');
        $('#collector-select2').select2({
            placeholder: 'Select garbage collector or type name',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalPayout,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "collector"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.name),
                                email: item.email,
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $("#email").val(e.params.data.email)
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function() {
            $("#email").val(null);
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Payout</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Payout</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none"
                                onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add Payout
                        </button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total
                                    Revenue</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span
                                        id="total-revenue">0</span></h3>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Amount of Revenue</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span
                                    className="current-date">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-success tx-semibold mg-b-8">Total
                                    Balance</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span
                                        id="total-balance">0</span></h3>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Amount of withholding.</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span
                                    className="current-date">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-danger tx-semibold mg-b-8">Total
                                    Disbursed</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span
                                        id="total-disbursed">0</span></h3>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Amount of Revenue after Deducting Commissions.</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span
                                    className="current-date">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Payouts</h6>
                                <div className="d-flex tx-18">
                                    <div className="tx-12 tx-color-03 align-items-center d-none d-sm-flex mg-r-15">
                                        <a href="javascript:void(0)" className="link-01 tx-spacing-1 tx-rubik d-flex align-items-center" onClick={() => $(`#dateRangePicker`).datepicker('show')}>
                                            <DateRangePicker id="dateRangePicker" onDateRangeSelect={handleDateRangeSelect}/>
                                            {selectedDate ?
                                                <div>
                                                    <span id="start_date">{moment(currDateRange?.start).format('DD/MM/YYYY')}</span>
                                                    <span className="mg-x-10">to</span>
                                                    <span id="end_date">{moment(currDateRange?.end).format('DD/MM/YYYY')}</span>
                                                </div> : <span>Select Date Range</span>}
                                            <ion-icon name="chevron-down-outline" aria-label="chevron down outline" role="img" className="md hydrated"></ion-icon>
                                        </a>
                                    </div>
                                    <a href="#" onClick={refreshTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-payout" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Collector</th>
                                        <th className="wd-20p">Amount</th>
                                        <th className="wd-10p">Source</th>
                                        <th className="wd-20p">Period</th>
                                        <th className="wd-20p">Date Issued</th>
                                        <th className="wd-10p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalPayout" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalPayout", "payout_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Payout" : "Add Payout"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} payout information.</p></div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <form id="payout_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                <div id="collectorWrapper" className="form-group parsley-input">
                                    <div className="d-flex justify-content-between">
                                        <label>Garbage Collector<span className="tx-danger">*</span></label>
                                    </div>
                                    <select className="form-control" id="collector-select2" data-width="100%"
                                            multiple="multiple"
                                            data-parsley-class-handler="#collectorWrapper"
                                            data-parsley-errors-container="#collectorWrapper"
                                            autoComplete="off"
                                            required>
                                    </select>
                                </div>

                                <div id="emailWrapper" className="form-group parsley-input">
                                    <label>Email Address</label>
                                    <input id="email" type="email" className="form-control"
                                           placeholder="Enter email address"
                                           autoComplete="none"
                                           data-parsley-class-handler="#emailWrapper"/>
                                </div>

                                <div id="typeWrapper" className="form-group parsley-input">
                                    <label>Mode of Payment<span className="tx-danger">*</span></label>
                                    <select className="custom-select" id="type" required>
                                        <option value="" disabled selected>Select</option>
                                        <option value="bank">Bank</option>
                                        <option value="momo">Mobile Money</option>
                                    </select>
                                </div>

                                <div id="refWrapper" className="form-group parsley-input">
                                    <label>Reference Number<span className="tx-danger">*</span></label>
                                    <input id="ref_number" type="text" className="form-control"
                                           placeholder="Enter reference number"
                                           autoComplete="none"
                                           data-parsley-class-handler="#refWrapper" required/>
                                </div>

                                <div id="amountWrapper" className="form-group parsley-input">
                                    <label>Amount<span className="tx-danger">*</span></label>
                                    <input id="amount" type="number" className="form-control"
                                           placeholder="Enter amount"
                                           autoComplete="none"
                                           step="0.01"
                                           data-parsley-class-handler="#amountWrapper"
                                           required/>
                                </div>

                                {stateReady ? <button disabled className="btn btn-success btn-block"><span
                                    className="spinner-border spinner-border-sm mg-r-10" role="status"
                                    aria-hidden="true"/>Please Wait</button> : editForm ?
                                    <button className="btn btn-success btn-block">Update Payout</button> :
                                    <button className="btn btn-success btn-block">Add Payout</button>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog"
                 aria-labelledby="confirmation" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-payout" className="btn btn-success btn-block">Yes,
                                Delete
                            </button>
                            <button type="button" className="btn btn-danger btn-block mt-0"
                                    data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Payout;
