import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import cogoToast from "cogo-toast";
import {truncate} from "../../../utils/trunate";
import moment from "moment/moment";
import {PhotoProvider, PhotoView} from "react-photo-view";
import ViewGMap from "../../../components/map/ViewGMap";

const Report = () => {

    document.title = "Report";

    const token = localStorage.getItem('jwtToken');

    const [images, setImages] = useState([]);
    const [coordinate, setCoordinate] = useState({});

    useEffect(() => {
        let table = $('#report_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Report Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Report",
                        fieldname: "name",
                        filter: {},
                        pageSize: data.start,
                        populate: "user",
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return capitalize(data.user.first_name) + " " + capitalize(data.user.last_name);
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.type;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return {
                            resolved : `<span class="badge badge-success tx-white">Resolved</span>`,
                            unresolved : `<span class="badge badge-warning tx-white">Unresolved</span>`,
                            rejected : `<span class="badge badge-danger tx-white">Rejected</span>`
                        }[data.status];
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY")
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_report"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link edit_company"><i class="fa fa-trash"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.view_report', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            setImages(extract_td.raw.photos);
            $("#tx-description").text(extract_td.raw.description);
            $("#tx-type").text(extract_td.raw.type);
            $("#tx-mobile").text(extract_td.raw.user.mobile_number);
            $("#tx-status").text(extract_td.raw.status);
            $("#tx-location").text(extract_td.raw.address);
            $("#tx-date").text(moment(extract_td.raw.date.iso).format("Do MMM, YYYY"));
            $("#tx-user").text(`${capitalize(extract_td.raw.user.first_name)} ${capitalize(extract_td.raw.user.last_name)}`);
            $("#tx-customer").attr('href', `#/user/${extract_td.raw.user._id}`).text(`${capitalize(extract_td.raw.user.first_name)} ${capitalize(extract_td.raw.user.last_name)}`);

            setCoordinate({lat: extract_td.raw.location.coordinates[0], lng: extract_td.raw.location.coordinates[1]});

            $("#modalReport").modal({ backdrop: 'static', focus: false, show: true });
        });

        return () => {
            table.destroy();
        }

    }, []);

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Reports</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Reports</h4>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Report</h6>
                                <div className="d-flex tx-18">
                                    <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#report_table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="report_table" className="table table-hover" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Customer</th>
                                        <th className="wd-20p">Type</th>
                                        <th className="wd-20p">Status</th>
                                        <th className="wd-20p">Date</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalReport" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close" onClick={() => {
                                    $('#modalReport').on('hidden.bs.modal', function () {
                                        $('.nav-link').removeClass('active').attr('aria-selected', 'false');
                                        $('.tab-pane').removeClass('show active');
                                        $('#info-tab5').addClass('active').attr('aria-selected', 'true');
                                        $('#info').addClass('show active');
                                    });
                                    setImages([])
                                }}><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Report</h4><p className="tx-13 tx-color-02 mg-b-0">Reported By: <span id="tx-user">---</span></p></div>
                            </div>
                            <div className="modal-body pd-t-10">
                                <ul className="nav nav-line nav-fill" id="myTab5" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="info-tab5" data-toggle="tab" href="#info" role="tab" aria-controls="home" aria-selected="true">Information</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="customer-tab5" data-toggle="tab" href="#location" role="tab" aria-controls="profile" aria-selected="false">Location</a>
                                    </li>
                                </ul>

                                <div className="tab-content mg-t-20 pd-sm-b-10 pd-sm-x-10" id="myTabContent5" >
                                    <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab5">
                                        <p><strong>Customer Name:</strong> <a href="#" target="_blank" id="tx-customer" className="text-capitalize link-02">---</a></p>
                                        <p><strong>Mobile Number:</strong> <span id="tx-mobile">---</span></p>
                                        <p><strong>Type:</strong> <span id="tx-type">---</span></p>
                                        <p><strong>Description:</strong> <span id="tx-description">---</span></p>
                                        <p><strong>Location:</strong> <span id="tx-location">---</span></p>
                                        <p><strong>Status:</strong> <span id="tx-status" className="text-capitalize">---</span></p>
                                        <p><strong>Report Date:</strong> <span id="tx-date">---</span></p>
                                        {images.length > 0 ? <PhotoProvider>
                                            <p className="tx-primary">Click Photos Below</p>
                                            <div className="img-group float-left">
                                                {images.map((item, index) => (
                                                    <PhotoView key={index} src={item}>
                                                        <img src={item} className="img wd-60 ht-60 rounded-circle" alt=""/>
                                                    </PhotoView>
                                                ))}
                                            </div>
                                        </PhotoProvider> : null}
                                    </div>
                                    <div className="tab-pane fade" id="location" role="tabpanel" aria-labelledby="customer-tab5">
                                        <ViewGMap coordinate={coordinate}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Report;
